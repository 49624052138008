import React from 'react';

export default function TeamMember(props) {

  return (
      <main>
          {/* props - photo, name, quals, description */}
          <div id="member card" className={'w-11/12 mx-auto py-8 text-gray-700 rounded-lg text-sm bg-gray-200 shadow-md'}>
            <div id="container" className='w-9/12 mx-auto lg:flex lg:justify-between lg:space-x-6 lg:w-11/12'>
              <div id="photo" className='w-11/12 mx-auto hover:grayscale md:w-9/12 lg:w-1/2 lg:mx-auto'>
                <img src={props.photo} alt={props.name} className='w-full h-72 object-cover rounded-lg shadow-lg sm:h-80 lg:h-full lg:object-cover'/>
              </div>
              <div id="the right" className='lg:w-1/2 lg:mx-auto'>
                  <div id="the content" className='pt-8 lg:pt-0'>
                    <h3 className='text-center uppercase text-xs font-bold lg:text-left'>director</h3>
                    <h2 className='text-center text-blue-900 font-bold font-worksans text-lg leading-tight lg:text-left lg:text-xl'>{props.name}</h2>
                    <div id="the green line" className='pt-4 border-b-2 border-green-500 '></div>
                    <p className='pt-4 pb-4 font-bold'>{props.quals}</p>
                  </div>
                  <div className='hidden py-4 text-justify text-sm rounded-md bg-gray-300 lg:block lg:text-base'>
                    <p className='w-11/12 mx-auto'>{props.description}</p>
                  </div>
              </div>  
            </div>
            <div className='w-10/12 mx-auto py-4 text-justify text-sm rounded-md bg-gray-300 lg:hidden'>
              <p className='w-11/12 mx-auto'>{props.description}</p>
            </div>          
                       
          </div>          
      </main>
  );
}
