import React from 'react';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import TeamMember from '../components/TeamMember';
import { useStaticQuery, graphql } from 'gatsby';
import Seo from '../components/Seo';
import AOS from 'aos';
import "aos/dist/aos.css";


export default function About() {
  const teamdata = useStaticQuery(
    graphql`
    query MyQuery {
      allContentfulTeamMember {
        nodes {
          name
          qualifications
          photo {
            file {
              url
            }
          }
          description
          bgColor
        }
      }
    }    
    `
  )

    // initializing the AOS
    React.useEffect(()=>{
      AOS.init({
        delay: 100,
        duration: 500,
      });
      AOS.refresh();
    }, []);

  return (
      <main className="">
        <Seo pagetitle="About" pagedescription="" />
        {/* HERO */}
        {/* Hero - mobile */}
        <section id="hero" className="relative pb-16 bg-cover bg-[url('../images/bgaboutclz.jpg')] sm:pb-20 lg:hidden">
        <div className=''><Nav pagelabel={"About Us"}/></div>

            <div id="hero container" className='text-gray-100'>
              {/* <div id="label" className='hidden py-1 absolute -top-4 inset-x-0 w-7/12 mx-auto text-center rounded-md bg-green-600 font-bold text-3xl font-worksans lg:hidden'>About Us</div> */}
              <h1 data-aos="fade-left" data-aos-duration="600" id="title" className='w-10/12 mx-auto font-worksans text-xl text-center antialiased'>About <span className='text-orange-400 font-bold text-xl'>Verdant Consulting</span></h1>
              <p data-aos="fade-left" data-aos-duration="1100" id="description" className='pt-4 pb-8 w-9/12 mx-auto text-sm text-center'>Established in 2018 by professionals with over 8 years experience. We are therefore <span className='text-orange-400'>highly experienced</span> in Environment, Health Safety and Project Management.</p>
              <div data-aos="fade-left" data-aos-duration="1600" id="cta button" className='w-1/2 mx-auto text-center'>
                  <Link to="/contact"><button className='py-2 px-5 rounded-md bg-green-700 uppercase text-center text-white font-bold text-xs font-ibmplex tracking-wide hover:bg-orange-600'>Contact us</button></Link>
              </div>
            </div>
        </section>

      {/* Hero - lg */}
      <div id="hero" className="hidden lg:block pb-28 h-full bg-cover bg-[url('../images/bgaboutclz-lg.jpg')]">
      <div className='lg:pt-6 pb-20 xl:w-11/12 xl:mx-auto'><Nav/></div>
        <div id="hero content container" className='flex items-center justify-between'>
          <div id="the left space" className='w-11/12'></div>
          <div id="the right - the content" className='text-left w-9/12'>
            <h1 data-aos="fade-left" data-aos-duration="600" className='font-worksans text-3xl antialiased text-white '>About <span className='text-orange-400 font-worksans font-bold text-3xl'>Verdant Consulting</span></h1>
            <p data-aos="fade-left" data-aos-duration="1100" className='pt-8 pb-16 text-white pr-16 '>Established in 2018 by professionals with over 8 years experience. We are therefore <span className='text-orange-400'>highly experienced</span> in Environment, Health Safety and Project Management.</p>
            <div data-aos="fade-left" data-aos-duration="1600" id="cta button" className=''>
              <Link to="/contact"><button className='py-2 px-5 rounded-md bg-green-700 uppercase text-center text-white font-bold text-xs font-ibmplex tracking-wide hover:bg-orange-600'>Contact us</button></Link>
            </div>
          </div>          
        </div>
        
      </div>

        {/* WHO WE ARE */}
        {/* Who We Are - mobile*/}
        <section data-aos="fade-up" data-aos-duration="250" data-aos-delay="50" id="who we are" className='py-16 w-10/12 mx-auto text-center sm:w-9/12 lg:hidden'>
          <h3 className=' uppercase text-rt font-semibold text-blue-700'>who we are</h3>
          <h2 className='text-verdantblack font-worksans text-2xl font-bold leading-tight tracking-tight antialiased'>A Little About Us</h2>
          <div id="image" className='py-8'>
            <StaticImage src="../images/infield.png" className='rounded-lg' alt="environemntal consultants standing at a site"/>
          </div>
          <p className='text-sm text-justify'>
            We are a diverse team of professionals looking to maximize our client’s environmental performance both as their respective commitments to regulatory compliance and our own pledge to supporting global sustainability goals. 
            We are passionate about nature and specifically, the indispensable value that a clean and healthy environment brings to communities, businesses and economies. As such, our network of leading environmental and earth science experts offers services beyond standard compliance protocols.
          </p>
        </section>

        {/* Who We Are - desktop */}
        <section data-aos="fade-up" className='pt-24 pb-32 w-10/12 mx-auto hidden lg:block lg:flex items-top justify-between space-x-10 xl:w-9/12'>
          <div id="left side" className='w-1/2'>
            <h3 className=' uppercase text-sm font-semibold text-blue-700'>who we are</h3>
            <h2 className='text-verdantblack font-worksans text-3xl font-bold leading-tight tracking-tight antialiased'>A Little About Us</h2>
            <div id="the green line" className='my-8 border-b-2 border-green-500'></div>
            <p className='text-justify'>
              We are a diverse team of professionals looking to maximize our client’s environmental performance both as their respective commitments to regulatory compliance and our own pledge to supporting global sustainability goals. 
              <br/>We are passionate about nature and specifically, the indispensable value that a clean and healthy environment brings to communities, businesses and economies. As such, our network of leading environmental and earth science experts offers services beyond standard compliance protocols.
            </p>
          </div>
            <div id="right side - the image" className='w-1/2 '>
              <StaticImage src="../images/infield.png" alt="environemntal consultants standing at a site" className="h-full rounded-lg"/>
            </div>
        </section>



        {/* Mission, Values */}
        <section data-aos="fade-up" className='w-11/12 mx-auto rounded-lg mb-16 bg-verdantblack sm:w-9/12 lg:w-10/12 lg:text-base lg:mb-32 xl:w-9/12'>
          <div id="container" className='py-16 w-10/12 mx-auto text-gray-100 text-sm'>
            <div className='lg:flex lg:items-top lg:justify-between'>
              <h2 className='text-center text-orange-400 text-xl font-bold lg:w-1/2 lg:text-left lg:text-2xl'>Our Mission</h2>
              <p className='py-8 text-justify lg:py-0 lg:w-1/2 lg:text-base'>
                To offer professional, reliable, environmental, health and safety solutions on sustainable development and environmental improvements to its clients. We contribute to each client's success by delivering quality services on time and on schedule; and provide solutions based on technical expertise that add value by aligning with our clients goals. 
                {/* <br/>We are dedicated to offering consultancy services that foster a sustainable future and lead to social and economical improvements in the communities we work with. */}
              </p>
            </div>
           
            <div className='lg:flex lg:justify-between'>
              <div className='lg:w-1/2 lg:mx-auto'></div>
              <div id="green line" className='border-b-2 border-green-700 lg:w-1/2 lg:mx-auto lg:border-orange-500 lg:my-10 lg:pr-16'></div>
            </div>

          <div className='lg:flex lg:items-top lg:justify-between'>
            <h2 className='pt-8 text-center text-orange-400 text-xl font-bold lg:pt-0 lg:w-1/2 lg:text-left lg:text-2xl'>Our Values</h2>
            <div className='pl-6 py-8 text-justify lg:py-0 lg:pl-4 lg:w-1/2 lg:mx-auto'>
              <ul className='list-disc text-left lg:text-base'>
                <li className=''>We act with integrity, are transparent and honest.</li>
                <li>We are accountable.</li>
                <li>We strive to provide high-quality services.</li>
                <li>We are committed to continuous improvement.</li>
                <li>We work as a team.</li>
              </ul>
            </div>
          </div>
            
          </div>
        </section>

        {/* Why Choose Us */}
        <section data-aos="fade-up" className="relative pt-16 pb-32 bg-cover bg-[url('../images/why-choose-us-clz.jpg')] lg:bg-center lg:pt-32 lg:pb-48">
          <div id="container" className='w-10/12 mx-auto'>
            <h2 className='text-gray-100 text-center text-2xl font-bold font-worksans lg:text-3xl'>Why Choose Us</h2>
            <div className='absolute top-32 inset-x-0 w-10/12 mx-auto px-6 text-justify py-8 bg-green-700 text-gray-100 text-sm rounded-md sm:w-8/12 lg:px-36 lg:w-8/12 xl:w-7/12 lg:top-56'>With Verdant, our clients are guaranteed of our availability 56 hours a week for face to face time in our offices, and we remain reachable 168hrs a week over phone and online. Save time on your project schedule and work with us. Our consultants will give your project their undivided attention from inception to hand over. We offer to carry the weight of compliance requirements for all aspects of environmental management off our client’s shoulders in a professional and ethical manner.</div>
          </div>
        </section>
        <div className='py-36 sm:py-28'></div>

        {/* Our Team */}
        <section data-aos="fade-up" className="xl:bg-gray-100 py-16">
          <div id="container" className='sm:w-9/12 sm:mx-auto md:w-7/12 lg:w-9/12 xl:w-7/12'>
            <h3 className='w-10/12 mx-auto uppercase text-rt font-semibold text-blue-700 text-center'>our team</h3>
            <h2 className='w-10/12 mx-auto text-verdantblack font-worksans text-2xl font-bold text-center leading-tight tracking-tight antialiased lg:text-3xl'>The Team That Makes the Magic Happen.</h2>
            <div data-aos="fade-up" id="the team" className='py-4'>
              {
                teamdata.allContentfulTeamMember.nodes.reverse().map(
                  (item)=>{
                    return(
                      <div data-aos="fade-right" className='py-4'><TeamMember photo={item.photo.file.url} name={item.name} quals={item.qualifications} description={item.description} bgcolor={item.bgColor}/></div>
                    )
                  }
                )
              }
            </div>
          </div>
          
        </section>

        {/* Our Company Goals */}
        <section data-aos="fade-up" id="goals" className='lg:flex lg:justify-between'>
          <div id="the rep image" className='lg:w-1/2'>
            <StaticImage src="../images/about-bottom.jpg" alt="bridge with people passing on it" className='sm:h-72 md:w-full lg:h-full'/>
          </div>
          <div id="projects content" className='pt-10 pb-16 bg-blue-900 lg:w-1/2'>
            <div id="container" className='w-11/12 mx-auto'>
              <h2 className='text-orange-500 text-center font-worksans text-xl font-bold leading-tight tracking-tight antialiased lg:text-3xl lg:text-left lg:px-10 xl:px-12'>Our Company Goals.</h2>
              <ul className='w-11/12 mx-auto list-disc py-6 px-6 mt-6 mb-8 text-sm text-justify text-gray-100 antialiased bg-blue-800 rounded-lg sm:w-10/12 lg:text-base lg:text-left'>
                <li>To strategically advance our client’s compliance with environmental requirements and fostering best practice for all industries we work with.</li>
                <li className='py-4'>To collaborate on designing strategic compliance solutions and enhance uptake into our client’s process flow.</li>                
                <li>To provide the best professional services at competitive rates while maintain high standards and a quality output.</li>
              </ul>
              <div id="cta button" className='text-center lg:text-left lg:px-10 xl:px-12'>
              <Link to="/services"><button className='py-2 px-5 rounded-md bg-green-700 uppercase text-center text-white font-bold text-xs font-ibmplex tracking-wide hover:bg-orange-400 lg:text-base'>see our services</button></Link>
              </div>
            </div>
          </div>
        </section>

        {/* Footer */}
        <div id="footer">
          <Footer/>
        </div>        
      </main>
  )
}